:root {
  --primary: #7b35bb;
  --primary-hover: #5c278b;
  --secondary: #dbc6ef;
  --secondary-hover: #cdb0e9;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #dde4ee;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #dde4ee;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #dde4ee;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #dde4ee;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #dde4ee;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #dde4ee;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

a {
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}

.cursor-pointer {
  cursor: pointer;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.before_pickup {
  color: #2e3338 !important;
  background-color: #dfe5ec !important;
}
.delivery_picked {
  background-color: #d9edfc !important;
  color: #1079c4 !important;
}
.delivery_failed {
  color: #f76408 !important;
  background-color: #fee6d7 !important;
}
.delivery_completed {
  color: #2aaa6e !important;
  background-color: #def7eb !important;
}
.returned_package {
  color: #542f9d !important;
  background-color: #e7dff6 !important;
}

.no-status {
  display: none;
}

.status-package {
  height: 32px;
  line-height: 24px;
  font-size: 15px;
  padding: 4px 12px 4px 28px;
  border-radius: 20px;
  position: relative;
  // &::before {
  //   padding-left: 12px;
  //   content: '\2022';
  //   font-size: 1.5em;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }
}

.status-company-type {
  height: 32px;
  line-height: 24px;
  font-size: 15px;
  padding: 4px 12px 4px 12px;
  border-radius: 20px;
  position: relative;
  // &::before {
  //   padding-left: 12px;
  //   content: '\2022';
  //   font-size: 1.5em;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }
}

mat-form-field {
  .loading-icon {
    position: absolute;
    right: 0;
    display: none;
  }
  &.loading {
    .loading-icon {
      display: inline-block;
    }
  }
}

.loading-icon {
  width: 1.25rem;
  height: 1.25rem;
  border: 2px solid gray;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
