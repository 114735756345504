// Define the range and step
$start: 0;
$end: 1000;
$step: 2;

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.mt-16 {
  margin-top: 16px;
}

.m-0 {
  margin: 0;
}

.m-8 {
  margin: $spacing * 2;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: $spacing;
}

.mt-6 {
  margin-top: $spacing * 2;
}

.mt-7 {
  margin-top: $spacing * 3;
}

.mt-8 {
  margin-top: $spacing * 4;
}

.mt-10 {
  margin-top: $spacing * 6;
}

.mt-12 {
  margin-top: $spacing * 8;
}

.mt-14 {
  margin-top: $spacing * 10;
}

.ms-4 {
  margin-left: $spacing;
}

.ms-6 {
  margin-left: $spacing * 2;
}

.ms-8 {
  margin-left: $spacing * 4;
}

.ms-10 {
  margin-left: $spacing * 6;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-4 {
  margin-bottom: $spacing;
}

.mb-6 {
  margin-bottom: $spacing * 2;
}

.mb-7 {
  margin-bottom: $spacing * 3;
}

.mb-8 {
  margin-bottom: $spacing * 4;
}

.mb-10 {
  margin-bottom: $spacing * 6;
}

.mb-12 {
  margin-bottom: $spacing * 8;
}

.mb-14 {
  margin-bottom: $spacing * 10;
}

.me-4 {
  margin-right: $spacing;
}

.me-6 {
  margin-right: $spacing * 2;
}

.me-7 {
  margin-right: $spacing * 3;
}

.me-8 {
  margin-right: $spacing * 4;
}

.me-10 {
  margin-right: $spacing * 6;
}

.p-4 {
  padding: $spacing;
}

.p-6 {
  padding: $spacing * 2;
}

.p-8 {
  padding: $spacing * 4;
}

.p-10 {
  padding: $spacing * 6;
}

.p-12 {
  padding: $spacing * 8;
}

.px-4 {
  padding-left: $spacing;
  padding-right: $spacing;
}

.px-6 {
  padding-left: $spacing * 2;
  padding-right: $spacing * 2;
}

.px-8 {
  padding-left: $spacing * 4;
  padding-right: $spacing * 4;
}

.px-10 {
  padding-left: $spacing * 6;
  padding-right: $spacing * 6;
}

.py-4 {
  padding-top: $spacing;
  padding-bottom: $spacing;
}

.py-6 {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
}

.py-8 {
  padding-top: $spacing * 4;
  padding-bottom: $spacing * 4;
}

.py-10 {
  padding-top: $spacing * 6;
  padding-bottom: $spacing * 6;
}

.pt-0 {
  padding-top: 0;
}

.pt-4 {
  padding-top: $spacing;
}

.pt-6 {
  padding-top: $spacing * 2;
}

.pt-7 {
  padding-top: $spacing * 3;
}

.pt-8 {
  padding-top: $spacing * 4;
}

.pt-10 {
  padding-top: $spacing * 6;
}

.pt-12 {
  padding-top: $spacing * 8;
}

.ps-0 {
  padding-left: 0;
}

.ps-4 {
  padding-left: $spacing;
}

.ps-6 {
  padding-left: $spacing * 2;
}

.ps-8 {
  padding-left: $spacing * 4;
}

.ps-10 {
  padding-left: $spacing * 6;
}

.pe-0 {
  padding-right: 0;
}

.pe-4 {
  padding-right: $spacing;
}

.pe-6 {
  padding-right: $spacing * 2;
}

.pe-8 {
  padding-right: $spacing * 4;
}

.pe-10 {
  padding-right: $spacing * 6;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-4 {
  padding-bottom: $spacing;
}

.pb-6 {
  padding-bottom: $spacing * 2;
}

.pb-7 {
  padding-bottom: $spacing * 3;
}

.pb-8 {
  padding-bottom: $spacing * 4;
}

.pb-10 {
  padding-bottom: $spacing * 6;
}

.pb-12 {
  padding-bottom: $spacing * 10;
}

.w-100 {
  width: 100% !important;
}

.w100 {
  width: 100% !important;
}

.w-50 {
  width: 50%;
}

.width-368 {
  width: 368px;
  margin: 0 auto;
}

.w-83 {
  width: 83px;
}

// Loop through and generate classes
@for $i from $start through $end {
  @if $i % $step == 0 {
    .miw-#{$i} {
      min-width: $i * 1px;
    }
    .w-#{$i} {
      width: $i * 1px;
    }
    .maw-#{$i} {
      max-width: $i * 1px;
    }
  }
}

.miw-83 {
  min-width: 83px;
}

.maw-83 {
  max-width: 83px;
}

.w-135 {
  width: 135px;
}

.miw-135 {
  min-width: 135px;
}

.maw-135 {
  max-width: 135px;
}

.w-140 {
  width: 140px;
}

.miw-140 {
  min-width: 140px;
}

.maw-140 {
  max-width: 140px;
}

.w-154 {
  width: 154px;
}

.miw-154 {
  min-width: 154px;
}

.maw-154 {
  max-width: 154px;
}

.w-170 {
  width: 170px;
}

.maw-160 {
  min-width: 160px;
}

.miw-170 {
  min-width: 170px;
}

.maw-170 {
  max-width: 170px;
}

.w-172 {
  width: 172px;
}

.miw-172 {
  min-width: 172px;
}

.maw-172 {
  max-width: 172px;
}

.w-175 {
  width: 175px;
}

.miw-175 {
  min-width: 175px;
}

.maw-175 {
  max-width: 175px;
}

.w-176 {
  width: 176px;
}

.miw-176 {
  min-width: 176px;
}

.maw-176 {
  max-width: 176px;
}

.w-177 {
  width: 177px;
}

.miw-177 {
  min-width: 177px;
}

.maw-177 {
  max-width: 177px;
}

.w-200 {
  width: 200px;
}

.miw-200 {
  min-width: 200px;
}

.maw-200 {
  max-width: 200px;
}

.w-232 {
  width: 270px;
}

.miw-232 {
  min-width: 232px;
}

.w-270 {
  width: 270px;
}

.miw-270 {
  min-width: 270px;
}

.maw-270 {
  max-width: 270px;
}

.w-300 {
  width: 300px;
}

.miw-300 {
  min-width: 300px;
}

.maw-300 {
  max-width: 300px;
}

.w-385 {
  width: 385px;
}

.miw-385 {
  min-width: 385px;
}

.maw-385 {
  max-width: 385px;
}
