.d-flex {
  display: flex;
}
.d-block {
  display: block !important;
}
.d-inline-block {
  display: inline-block !important;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}
.align-items-baseline {
  align-items: baseline;
}
.flex-1 {
  flex: 1;
}
.flex-auto {
  flex: auto;
}
.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-gap--1 {
  gap: 1rem;
}

.flex-grow {
  flex-grow: 1;
}
