@use '@angular/material' as mat;
/* You can add global styles to this file, and also import other style files */
@import './assets/styles/bootstrap.scss';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '../../../libs/shared-ui/src/lib/styles/custom-theme.scss';
@import '../../../libs/shared-ui/src/lib/styles/fonts/fonts.scss';

:root {
  --sidebar-width: 230px;
}

html,
body {
  font-family: 'Noto Sans JP';
  font-weight: 500;
  color: var(--glom-text-clor);
}

.mat-bottom-sheet-container {
  padding: 0 !important;
  max-height: var(--gl-doc-height) !important;
  background: unset !important;
  box-shadow: unset !important;
}

$common-primary: mat.define-palette($light-primary-palette);
$common-accent: mat.define-palette($light-accent-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$common-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $common-primary,
      accent: $common-accent,
    ),
    typography:
      mat.define-typography-config(
        $font-family: 'Noto Sans JP',
        $button: mat.define-typography-level(15px, 24px, 500),
      ),
    density: -3,
  )
);
.quill-editor-custom {
  width: 100%;
  &.error {
    &.ng-touched {
      .ql-toolbar {
        &.ql-snow {
          border-color: #ba1a1a !important;
          border-bottom-color: #dde4ee !important;
        }
      }
      .ql-container {
        &.ql-snow {
          border: 1px solid #ba1a1a !important;
          border-top-color: #dde4ee !important;
        }
      }
    }
  }
}
.ql-toolbar {
  margin-bottom: 48px;
  &.ql-snow {
    font-family: 'Noto Sans JP' !important;
    font-weight: 500;
    color: var(--glom-text-clor);
    border-radius: 8px 8px 0 0;
    border: 1px solid #dde4ee !important;
  }
  .ql-formats {
    border-left: 1px solid #dde4ee !important;
    padding-left: 15px;
    &:first-child {
      padding-left: 0;
      border-left: 0 !important;
    }
  }
}
.ql-container {
  font-family: 'Noto Sans JP' !important;
  color: var(--glom-text-clor);
  height: unset;
  &.ql-snow {
    border-radius: 0 0 8px 8px;
    border: 1px solid #dde4ee !important;
  }
  .ql-tooltip.ql-flip {
    z-index: 1;
  }
  .ql-editor {
    height: 300px;
    padding: 8px 16px !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    strong {
      font-weight: 600;
    }
    // ol,
    // ul {
    //   padding-left: 0 !important;
    // }
  }
}
.email-setting-cell-custom {
  .ng-star-inserted {
    color: #254ce4;
    text-decoration: underline;
  }
}
.ql-editor {
  min-height: 300px;
  padding: 8px 16px !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  strong {
    font-weight: 600;
  }
  // ol,
  // ul {
  //   padding-left: 0 !important;
  // }
  &.render-body-html {
    min-height: unset;
    padding: unset !important;
    pre {
      background-color: #23241f;
      white-space: pre-wrap;
      margin-bottom: 5px;
      margin-top: 5px;
      padding: 5px 10px;
      border-radius: 3px;
      color: #f8f8f2;
    }
    blockquote {
      border-left: 4px solid #ccc;
      margin: 5px 0 5px 0;
      padding-left: 16px;
    }
    a {
      text-decoration: underline;
      color: #06c;
    }
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($common-light-theme);
