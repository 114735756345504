h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
}

.h1,
h1 {
  font-size: 30px;
  line-height: 40px;
}
.h2,
h2 {
  font-size: 20px;
  line-height: 28px;
}
.h3,
h3 {
  font-size: 16px;
  line-height: 24px;
}

.h4,
h4 {
  font-size: 14px;
  line-height: 20px;
}

.h5,
h5 {
  font-size: 14px;
  line-height: 20px;
}
.h6,
h6 {
  font-size: 12px;
  line-height: 16px;
}

strong {
  font-weight: 500;
}
p {
  margin-top: 0;
}

.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.inline-block {
  display: inline-block;
}
.none {
  display: none;
}

.text-highlight {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #2c1f50 !important;
  padding: 0;
  margin: 0;
}
.reset-border {
  border: 0 !important;
}
