// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import './theme/light.palette.scss';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@import './fonts/fonts.scss';
@include mat.core();

:root {
  --glom-primary: #254ce4;
  --glom-white: #ffffff;
  --glom-text-clor: #14181f;
  --glom-border-color: #dde4ee;
  --glom-error: #ba1a1a;
  --mdc-outlined-text-field-container-shape: 8px;
  --mat-table-row-item-label-text-size: 15px;
  --mat-table-row-item-label-text-weight: 500;
  --mdc-outlined-text-field-outline-color: #dde4ee;
  --mdc-outlined-text-field-error-focus-outline-color: #ba1a1a;
  --mdc-outlined-text-field-error-hover-outline-color: #ba1a1a;
  --mdc-outlined-text-field-error-outline-color: #ba1a1a;
  --mat-select-invalid-arrow-color: #ba1a1a;
  --mat-option-label-text-color: #14181f;
}

html,
body {
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-unelevated-button.mat-basic {
  height: 48px;
  border-radius: 8px;
  padding: 0 1.25rem;
  width: max-content;
  img {
    margin-right: 8px;
  }
}

.mat-mdc-unelevated-button.mat-accent {
  color: #254ce4 !important;
}

.mat-mdc-unelevated-button.mat-primary:not(.mat-mdc-button-disabled) {
  color: #ffffff !important;
  background: linear-gradient(180deg, #2f71ff 0%, #1856db 100%) !important;
}

.mat-mdc-unelevated-button[disabled],
.mat-mdc-unelevated-button.mat-mdc-button-disabled {
  background-color: #bdc7d6 !important;
  color: #7a8fae !important;
}

.mat-mdc-unelevated-button.mat-accent.btn-cancel {
  background-color: #ffffff !important;
  border: 1px solid #bdc7d6;
  color: #14181f !important;
}

.mat-mdc-unelevated-button.mat-basic {
  background-color: #ffffff !important;
  border: 1px solid #bdc7d6;
  color: #14181f !important;
}

.mat-mdc-unelevated-button {
  width: 100%;
  // max-width: 528px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  // height: 42px;
  width: 100%;
  // max-width: 208px;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 8px !important;
}

.form-feild-custom,
.form-field-custom {
  padding-top: 0px;
  font-size: 18px;
  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 8px;
    mat-label {
      color: var(--glom-text-clor);
      font-size: 15px;
      line-height: 24px;
      transform: unset;
      top: -30px;
    }
  }
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined {
    height: 44px;
    .mat-icon {
      opacity: 0.6;
    }
  }
  .mdc-notched-outline--notched .mdc-notched-outline__notch {
    border-top: 1px solid;
  }
  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
    .mdc-notched-outline__notch {
    border-left: unset;
  }
  .mdc-notched-outline__trailing,
  .mdc-notched-outline__leading {
    // background-color: #F9F9FB
  }
  .mat-mdc-form-field-icon-prefix > .mat-icon,
  .mat-mdc-form-field-icon-suffix > .mat-icon {
    padding: 0;
    margin-right: 12px;
  }

  &.form-feild-textarea {
    .mat-mdc-text-field-wrapper {
      border: 2px solid #254ce4;
      background-color: unset;
      border-radius: 8px;
      padding-right: 4px;
      height: max-content;
      textarea::-webkit-scrollbar {
        width: 6px; /* Adjust the width as needed */
      }
      textarea::-webkit-scrollbar-thumb {
        background-color: #98a2b3; /* Change the thumb color */
        -webkit-border-radius: 4px;
        border-radius: 4px;
      }

      textarea::-webkit-scrollbar-track {
        background-color: #dde4ee; /* Change the track color */
        -webkit-border-radius: 4px;
        border-radius: 4px;
      }
    }
  }
  .mdc-text-field--disabled {
    color: #4d5d75;
    .mdc-notched-outline__trailing,
    .mdc-notched-outline__leading {
      background-color: rgb(163 176 195 / 35%);
    }
    input {
      color: #4d5d75;
    }
  }
}

::ng-deep .form-feild-custom:hover {
  border-color: var(--glom-primary);
}

// Custom css search form
.search-form {
  .btn-search-form {
    width: max-content;
    height: 44px;
    min-width: 102px;
  }
}

// Custom tab
.mat-mdc-tab.mdc-tab.mdc-tab-indicator--active {
  color: #14181f;
  background-color: #dde4ee;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.mat-mdc-tab .mdc-tab__text-label {
  font-size: 15px;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: #14181f !important;
  font-weight: 500;
}

.bottom-sheet--luggage {
  height: 100%;
}

.mat-mdc-form-field-error {
  font-size: 13px !important;
  font-weight: 500;
  line-height: 21px;
  color: #ba1a1a !important;
}

.mdc-checkbox__background {
  border-color: #254ce4 !important;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background,
.mdc-checkbox
  .mdc-checkbox__native-control[data-indeterminate='true']:enabled
  ~ .mdc-checkbox__background
  .mdc-checkbox
  .mdc-checkbox__native-control:enabled
  ~ .mdc-checkbox__background {
  background-color: #254ce4 !important;
}
.mdc-checkbox__checkmark {
  color: #ffffff !important;
}

.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle,
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle,
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: #254ce4 !important;
}

mat-option {
  white-space: break-spaces;
  word-break: break-all;
}


.btn-cancel-custom.mat-mdc-unelevated-button .mat-mdc-button-persistent-ripple::before {
  background-color: #ffffff;
}